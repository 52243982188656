:root{
	--main-text-bg: rgba(255, 255, 255, 0.75);
	--main-color: #301c7b;
	--secondary-color: rgba(84, 255, 219, 1);
	--color-warning: red;
}

.App {
  text-align: center;
  min-width: 100vw;
  min-height: 100dvh;
}


/* Home */
section#home{
	display: grid; 
	grid-template-columns: 1.5fr 1fr; 
	grid-template-rows: 1fr;
	min-height: 100dvh;
}

section#step{
	display: grid; 
	grid-template-columns: 1fr 2fr 1fr;
	grid-template-rows: 12% 80% 8%;
	min-height: 100dvh;
}

section#step form{
	grid-column: 2;
	grid-row: 2;
}

section#step form #main{
	min-height: 80%;
	align-content: center;
}

section#step form iframe{
	width: 100%;
	height: 100%;
}

.home-text h1,
.home-text h2{
	background-color: var(--main-text-bg);
	color: var(--main-color)
}

.main_logo{
	position: absolute;
	bottom: 0;
}

.main_logo img{
	width: 240px;
	background-color: transparent;
	cursor: pointer;
}

.terms-text{
	color: var(--main-color);
  cursor: pointer;
  font-weight: bold;
	text-decoration: underline;
	border: none;
	background-color: transparent;
}

.btn-success{
	background-color: var(--main-color) !important;
}

form .error{
	color: var(--color-warning);
}

@media screen AND (max-width: 1000px){
	section#home{
		grid-template-columns: 1fr 1fr;
	}

	section#step{
		grid-template-columns: 1fr 4fr 1fr;
	}
}

@media screen AND (max-width: 768px){
	section#home{
		grid-template-columns: 1fr; 
  		grid-template-rows: 1fr 1fr;
	}

	section#home>div:last-child{
		grid-row: 1;
		height: 100dvh;
	}

	section#step{
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
	}

	section#step form{
		grid-column: 1;
		grid-row: 1;
	}
}

.loading::before {
	content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99;
		background-image: url("./assets/images/loader.svg");
		background-repeat: no-repeat;
    background-position: center;
    background-size: 50% 50%;
}

img{
	max-width:100%;
	max-height:100%;
}

img.form-logo{
	width: 40%;
}

.hidden{
	visibility: hidden;
}