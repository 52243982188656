.steps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: relative;
}

.step-container {
	max-width: 400px;
	width: 100%;
	z-index: 1;
	height: 90%;
}

.container .steps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: relative;
}

.steps .circle {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 50px;
	background: #e9ecef;
	font-family: "Quicksand", sans-serif;
	border-radius: 50%;
	font-size: 1.2rem;
}

.steps .circle.active {
	background: var(--secondary-color);
}

.steps .progress-bar {
	position: absolute;
	height: 4px;
	width: 100%;
	background: #e9ecef;
	z-index: -1;
}

.steps .circle:is(:not(:nth-child(1)))::before{
	position: absolute;
	content: "";
	border-bottom: 6px solid #e9ecef;
	z-index: -1;
	margin-right: 40%;
}

.steps .circle.active:is(:not(:nth-child(1)))::before{
	border-bottom: 6px solid var(--secondary-color);
}

.steps:has(:nth-child(2)) .circle::before{
	width: calc((100% / 1) - 10%);
	margin-right: calc(100% / 1.15);
}

.steps:has(:nth-child(3)) .circle::before{
	width: calc((100% / 2) - 10%);
	margin-right: calc(100% / 2.15);
}

.steps:has(:nth-child(4)) .circle::before{
	width: calc((100% / 3) - 10%);
	margin-right: calc(100% / 3.15);
}

.steps:has(:nth-child(5)) .circle::before{
	width: calc((100% / 4) - 10%);
	margin-right: calc(100% / 4.15);
}

.steps:has(:nth-child(6)) .circle::before{
	width: calc((100% / 5) - 10%);
	margin-right: calc(100% / 5.15);
}

.steps:has(:nth-child(7)) .circle::before{
	width: calc((100% / 6) - 10%);
	margin-right: calc(100% / 6.15);
}